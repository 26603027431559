<template> 
  <card
    v-model="item"
  >
    <template #header>
      <b-row align-v="center" class="d-flex flex-nowrap justify-content-between w-100">
        <b-icon icon="facebook" class="ml-1" :class="$store.state.appConfig.layout.skin == 'dark' ? 'facebook-light' : 'facebook-blue'" font-scale="2.5"/>
        <h2 class="d-flex flex-nowrap px-1" >Facebook</h2>
        <feather-icon
          :icon="active ? 'CheckIcon' : 'XIcon'"
          :class="active ? 'text-primary' : 'text-danger'" 
          size="30"
        />
      </b-row>
    </template>
    <template #footer>
      <div v-if="active" class="d-flex justify-content-between">
        <b-button variant="danger" class="btn-icon" @click="$emit('delete')">
          <b-icon
            icon="trash"
          />
          {{ $t("common.terms.delete") }}
        </b-button>

        <b-button variant="warning" class="btn-icon" @click="$emit('refresh')">
          <b-icon
            icon="arrow-clockwise"
          />
          {{ $t("common.terms.refresh") }}
        </b-button>
      </div>
      <div class="d-flex justify-content-end" v-else>
        <b-button 
          class="text-uppercase facebook-button btn-icon" 
          @click="$emit('openAuth')"
        >
          <b-icon
            class="mr-1"
            icon="facebook" 
          />
          {{ $t("common.terms.add") }}
        </b-button>
      </div>
    </template>
  </card>
</template>

<script>
import { BImg, BIcon, BRow, BCol, BButton } from 'bootstrap-vue'
import Card from '@/layouts/components/ThirdPartyApps/Card.vue'

  export default {
    components: {
      BImg,
      BIcon,
      BRow,
      BCol,
      Card,
      BButton
    },
    props: {
      value: {
        type: Object,
        default: undefined,
      }
    },
    computed: {
      item: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      },
      active() {
        return this.item.token ? true : false
      }
    }
  }
</script>

<style lang="scss" scoped>
.facebook-blue {
  color: #1877F2
}

.facebook-light {
  color: #ffffff
}

.facebook-button {
  background-color: #1877F2 !important;
  border-color: #1877F2 !important;
}

</style>